.ae-container{
    display: flex;
    flex-direction: column;
}

.ae-well-done{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #D64200;
}

.ae-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #4B4F58;
}

.ae-points{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: #D64200;
    margin-top: 25px;
}

.ae-points-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #D64200;
}


.ae-button{
    background:  #D64200;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #FFFFFF;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-transform: capitalize;
}

.ae-img{
    width: 96.93px;
    height: 105.6px
}