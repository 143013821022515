.accordion-parent{
    width: 300px;
    height: auto;
    border: 1px solid #C2C4C8;
    border-radius: 4px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.accordion-label{
    font-size: 16px;
    font-weight: 700;
}

div[slot="content"] {
    background: #C2C4C8
  }
