.p-header-container{
    display: flex;
    align-items: center;
    height: 64px;
    padding: 14px 16px;
    background: #F3F3F3;
}

.p-header-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
    margin-left: 16px;
}

.p-ion-grid{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 24px;
    padding: 0 32px;
}

.p-ion-row{
    margin-top: 4px;
}

.p-name{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #272727;
}

.p-email{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4B4F58;
}

.p-arrow{
    width: 8px;
    height: 14px;
}

.p-go-to-container{
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #C2C4C8;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    max-width: 100%;
}

.p-go-to-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
}

.p-router-link{
    margin-top: 12px;
    width: 100%;
}


.p-rows-container{
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.p-log-out-container{
    display: flex;
    align-items: center;
    margin-top: 32px;
}

.p-log-out-icon{
    height: 20px;
    margin-right: 11.75px;
}

.p-log-out-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
}

