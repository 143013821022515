.ma-header-container{
    display: flex;
    align-items: center;
    height: 64px;
    padding: 14px 16px;
    background: #F3F3F3;
}
.ma-header-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
    margin-left: 16px;
}

.ma-ion-grid{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 24px;
    padding-left: 32px;
    padding-right: 32px;
}

.ma-button{
    background: #D64200;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #FFFFFF;
    margin-top: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-transform: capitalize;
}

.ma-button-disabled{
    background: #E6E6E7;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #272727;
    margin-top: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-transform: capitalize;
}


.ma-label{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    margin-bottom: 2px;
}

.ma-field{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    width: 296px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #626875;
    border-radius: 24px;
}

.ma-article-textfield{
    display: flex; 
    flex-direction: column; 
    align-items: flex-start;
    margin-bottom: 12px;
}

.ma-button-disabled{
    background: #E6E6E7;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #272727;
    margin-top: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-transform: capitalize;
}