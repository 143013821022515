
.error-payment-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    background-color: transparent;
}
.error-payment-header{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 18px;
}

.error-payment-header-text{
    font-size: 22px;
    font-weight: 700;
    font-family: 'Poppins';
    font-style: normal;
}

.error-payment-icon{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 35px;
}

.error-payment-description{
    display: flex;
    align-items: center;
    width: 100%;
    text-align: center;
}

.error-payment-description-text{
    font-size: 16px;
    font-weight: 400;
    color: #4B4F58;
}