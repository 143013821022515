.h-ion-header{
    display: flex; 
    align-items: center; 
    padding-right: 14px;
    padding-left: 14px;
    
}

.h-ion-grid{
    display: flex;
    align-items: center;
    padding: 0;
}

.h-ion-img {
    width: 192px;
    height: 32px;
}

.h-ion-menu-button{
    color: #272727;
    font-size: 24px;
    padding: 0;
    margin-right: 10px;
}

.h-ion-avatar{
    padding: 0;
    height: 36px;
    width: 36px;
}

.h-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #272727;
}

.h-description{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4B4F58;
}

.h-popover{
    --width: 336px;
}

.h-level{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    color: #D64200;
    margin-top: 4px;
}

.h-popover-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.h-popover-content-child{
    display: flex;
}

.h-divider{
    width: 304px;
    height: 1px;
    border: 1px solid #C2C4C8;
    margin: 16px 0 18px 0;
    
}

.h-main-container{
    padding: 16px 14px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
}

.h-log-out-container{
    display: flex;
    align-items: center;
}

.h-log-out-icon{
    height: 20px;
    margin-right: 11.75px;
}

.h-log-out-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4B4F58;
}

.progress-avatar {
    position: relative;
    width: 62px; /* Ancho del contenedor */
    height: 62px; /* Alto del contenedor */
    margin-right: 16px;
  }
  
  .progress-bar {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    --background: #C2C4C8;
    --progress-background: #ED4D07; /* Color de fondo del progreso */
    --progress-height: 6px; /* Altura de la línea de progreso */
  }
  
  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px; /* Ancho del avatar */
    height: 40px; /* Alto del avatar */
    border-radius: 50%; /* Para hacer el avatar redondo */
  }
  
  .avatar::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: 48px;
    height: 48px;
    border: 4px solid #fff;
    border-radius: 50%;
    z-index: -1;
    background-color: white;
  }

  
  
  


  
  
  