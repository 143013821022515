.ls-container{
    padding: 16px 32px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ls-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #272727;
}

.ls-box{
    background: #FFFFFF;
    border: 1px solid #C2C4C8;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 12px 16px;
    width: 100%;
    margin-top: 16px;
    display: flex;
    align-items: center;
    min-height: 80px;
    &.empty-live{
        align-items: center;
        justify-content: center;
        cursor: default;
    }
}

.ls-box-first-child{
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
}

.ls-date{
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
}

.ls-box-second-child{
    padding: 0;
    display: flex;
    flex-direction: column;
}

.ls-chat-name{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.004em;
    color: #D64200;
}

.ls-chat-description{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #626875;
    margin-top: 4px;
}

.ls-button{
    height: 40px;
    left: 21px;
    top: 288px;
    border-radius: 100px;
    padding: 10px 24px 10px 16px;
    border: 1px solid #898E9C;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.004em;
    color: #D64200;
    margin-top: 24px;
}