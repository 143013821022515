.ms-container{
    display: flex;
    flex-direction: column;
}

.ms-title-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 8px;
    width: 100%;
    height: 56px;
    background: #393A3F;
}

.ms-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}


.ms-child {
    display: flex;
    flex-direction: column;
}

.ms-month-year{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #232735;
}