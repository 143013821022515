.dc-container{
    display: flex;
    flex-direction: column;
}

.dc-text-container{
    display: flex;
    flex-direction: column;
    padding: 31.5px 32.5px 0 32.5px;
    margin: 0;
}

.dc-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #272727;
}

.dc-trainer-name{
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    color: #272727;
}

.dc-list-item{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4B4F58;
    text-align: left;
    
}
.black-mode {
    color: rgb(113, 118, 123);
}
@media screen and (max-width: 1300px) {
    .dc-list-item {
      font-size: 12px !important;
      max-width: 270px !important;
    }
    .links-dc {
        width: 100% !important;
    }
  }

.dc-trainer-container{
    margin-top: 24px;
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
}

ul {
    padding-inline-start: 0;
}
.loader-dc {
    height: 250px;
    display: flex;
    margin: 0 auto;
}