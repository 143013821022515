.f-child-exercise {
    width: 90%;
    padding: 12px 14px;
}

.f-excercise-container{
    display:flex;
    width: 100%;
    align-items: end;
}

.f-excercise-name-module{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    width: 220px;
    color: #272727;
}

.f-excercise-name-module-disabled{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    width: 220px;
    color: #898E9C;
}

.f-aviable-exercise{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-style: italic;
    color: #168404;
    margin-bottom: 5px;
}

.f-unaviable-exercise{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    font-style: italic;
    color: #A81E16;
    margin-bottom: 5px;
}

.f-time{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #4B4F58;
    margin-left: 5.5px;

}

.f-module-name{
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #A81E16;
}

.f-play-button{
    border-radius: 100px;
    border: 1px solid #898E9C;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.004em;
    color: #D64200;
}

.f-button-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.004em;
    color: #D64200;
}


.f-icon-container{
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.f-circle{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5em;
    height: 2.5em;
    border: 1px solid  #D64200;
    border-radius: 50%;
}
.f-circle-disabled{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5em;
    height: 2.5em;
    border: 1px solid  #4B4F58;
    border-radius: 50%;
}

.f-icon{
    height: 13.5px;
    width: 14.62px; 
    fill: #D64200;
}
.f-icon-disabled{
    height: 13.5px;
    width: 14.62px; 
    fill: #7a7c81;
}

.f-icon-row{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }


.f-trash-icon{
    margin-right: 10px;
    color: #B62D25;
}

.f-trash-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #B62D25;
}

.f-notes-title{
    width: 100%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    margin-top: 26px;
}

.f-counter{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #393A3F;
    margin-top: 4px;
}
