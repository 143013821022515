.header{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.sub-header{
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 10px;
}

.text{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
}