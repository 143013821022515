.r-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.r-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #272727;
}

.r-question{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4B4F58;
    margin-top: 16px;
}

.r-add-comment{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.004em;
    text-decoration-line: underline;
    color: #D64200;
    margin-top: 34.5px;
}