.mst-container{ 
    width: 100%;
    display: flex;
}
  /* Desktops and laptops ----------- */
  @media only screen and (min-width: 1224px) {
    .video-training-player {
        height: calc(100% - 48%) !important;
    }
  }
.hide {
    display: none;
}
