.qt-container{
    width: 100%;
    background-color: #393A3F;
    padding: 16px 32px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
}

.qt-title{
    font-size: 18px;
    font-weight: 600;
    font-family: Poppins;
    line-height: 24px;
    color: #FFFFFF;
}

.qt-subtitle{
    font-size: 14px;
    font-weight: 400;
    font-family: Lato;
    line-height: 20px;
    color: #E6E6E7;
}

.qt-description{
    font-size: 14px;
    font-weight: 400;
    font-family: Lato;
    line-height: 20px;
    color: #E6E6E7;
    margin-top: 24px;
}

.qt-button{
    background: #FF793F;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #161616;
    margin-top: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.qt-button-disabled{
    background: #626875;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #272727;
    margin-top: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.qt-available-excercises {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.004em;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-top: 12px;
}

.pop-over-entrenamiento{
    color: var(--neutral-800, #272727);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}