.cp-modal {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 12px;
  }
  
  .wrapper{
    background: #F3F3F3;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    width: 328px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 21.62px 16px;
  }
  
  .cs-icon-row{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  .cs-icon{
    color: #1E1E1E;
    font-weight: bold;
  }

  .cs-confirm-button{
    height: 40px;
    width: 100%;
    border-radius: 100px;
    padding: 10px 24px 10px 16px;
    border: 1px solid #898E9C;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.004em;
    color: #D64200;
    margin-top: 32px;
}

.cs-mantain-button{
    background:  #D64200;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #FFFFFF;
    margin-top: 16px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
}

.cs-important-container{
    padding: 16px 8px;
    gap: 4px;
    position: relative;
    width: 100%;
    border: 1px solid #ED4D07;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
}

.cs-important-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #D64200;

}

.cs-important-subtext{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #4B4F58;
}

.cs-cancel-suscription{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #D64200;
}


.cs-cancel-suscription-subtext{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #4B4F58;
    margin-top: 8px;
}

  

  
 
  
