.f-header-container{
    display: flex;
    align-items: center;
    height: 64px;
    padding: 14px 16px;
    background: #F3F3F3;
}

.f-header-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
    margin-left: 16px;
}

.f-container{
    padding: 16px 32px;
}

.f-images-container{
    padding: 0;
    margin-top: 16px;
}

.f-block{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #B83A02;
}

