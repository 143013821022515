.ul-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 12px;
}

.wrapper{
  background: #F3F3F3;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21.62px 16px;
}

.ul-icon-row{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.ul-icon{
  color: #1E1E1E;
  font-weight: bold;
}

.ul-img{
  width: 56px;
  height: 56px;
  margin-top: 20px;
}


.ul-name{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #272727;
  margin-top: 8px;
}

.ul-description{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #B83A02;
  margin-top: 4px;
}


.ul-upgrade-level{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #D64200;
  margin-top: 32px;
  text-align: center;
}


.ul-text{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #4B4F58;
  width: 296px;
}

.ul-start{
  padding: 10px 24px;
  height: 40px;
  background: #D64200;
  border-radius: 100px;
  color: #FFFFFF;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.004em;
  width: 100%;
  margin-top: 32px;
}

