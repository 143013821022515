.ab-container{
    display: flex;
    padding: 0 32px;
    margin-top: 32px;
    flex-direction: column;
}
.ab-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #B83A02;
}
.ab-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
    color: #4B4F58;
}
.ab-button{
    background:  #D64200;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #FFFFFF;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-transform: capitalize;
}
.ab-renovation-button{
    height: 40px;
    border-radius: 100px;
    padding: 10px 24px 10px 16px;
    border: 1px solid #898E9C;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.004em;
    color: #D64200;
    margin-top: 24px;
    width: 100%;
}
.ab-subscribe-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #898E9C;
    margin-top: 25px;
}
.ab-terms{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    color: #B83A02;
}
.ab-duration{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #272727;
}
.ab-date{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #4B4F58;
    margin-top: 8px;
}