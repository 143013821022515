.ion-grid-title-container{
    min-height: 88px;
    background: #F3F3F3;
    border-radius: 0px 0px 40px 0px;
    padding: 16px 32px;
    display: flex; 
    flex-direction: column;
}

.ion-grid-title-container-black{
    min-height: 88px;
    background: #393A3F;
    border-radius: 0px 0px 40px 0px;
    padding: 16px 32px;
    display: flex; 
    flex-direction: column;
}

.ion-text-title{
    font-size: 22px;
    font-weight: 700;
    font-family: Poppins;
    line-height: 32px;
    color: #D64200;
}

.ion-text-question{
    font-size: 20px;
    font-weight: 700;
    font-family: Lato;
    line-height: 24px;
    color: #272727;
}

.ion-text-question-black{
    font-size: 20px;
    font-weight: 700;
    font-family: Lato;
    line-height: 24px;
    color: #E6E6E7;
}