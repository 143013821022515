.cg-container{
    display: flex;
    padding: 0 32px;
    margin-top: 32px;
    flex-direction: column;
}

.cg-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #B83A02;
}

.cg-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 285px;
    margin-top: 8px;
}

.cg-subscribe-container{
    background: #FFF6F2;
    border: 1px solid #D64200;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
}

.cg-subscribe-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #D64200;
}

.cg-subscribe-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #4B4F58;
}

.cg-button{
    background:  #D64200;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #FFFFFF;
    margin-top: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-transform: capitalize;
}

.cg-subscribe-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #898E9C;
    margin-top: 32px;
}

.cg-terms{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    color: #B83A02;
}

