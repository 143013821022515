.cdtTimer-container{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: rgba(210, 207, 207, 0.8);
    /* opacity: 0.5; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: 130px;
    z-index: 999;
}
.back-container {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(210, 207, 207, 0.8);
    position: absolute;
    margin-top: 40px;
    z-index: 999;
}