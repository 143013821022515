.ei-container{
    display: flex;
    flex-direction: column;
}

.ei-well-done{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #D64200;
}

.ei-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #4B4F58;
}

.ei-child-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    gap: 4px;
    width: 100%;
    border: 1px solid #D64200;
    border-radius: 4px;
    margin-top: 24px;
}

.ei-child-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #D64200;
}

.ei-child-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #4B4F58;
}

.ei-button{
    background:  #D64200;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #FFFFFF;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-transform: capitalize;
}