.ba-container{
    display: flex;
    flex-direction: column;
    padding: 0;
}

.ba-child{
    display: flex;
    flex-direction: column;
    margin: 16.5px 32px;
    padding: 0;
}

.ba-incharge-of{
    padding: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
}

.ba-avatar{
    height: 48px;
    width: 48px;
    border-radius: 100px;
}

.ba-trainer-box{
    display: flex;
    padding: 16px 8px;
    background: #F3F3F3;
    box-shadow: 0px 8px 12px rgba(58, 5, 2, 0.1), 0px 2px 4px rgba(58, 5, 2, 0.3);
    border-radius: 4px;
    gap: 8px;
    margin-top: 8px;
    align-items: center;
}

.ba-trainer-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ba-trainer-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #D64200;
}

.ba-trainer-role{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
}

.ba-single-module-container{
    padding: 19px 0;
    width: 100%;
}

.ba-module-name{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #272727;
    margin-top: 5px;
    cursor: pointer;
}

.ba-circle {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 4px solid #C2C4C8;
    background-color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ba-circle-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.004em;
    color: #272727;
}

.ba-trainer-box-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ba-trainer-description{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #4B4F58;
}

.ba-lock-icon{
    color: #898E9C; 
    height: 26px;
    width: 19px;
}

.ba-included-w-subscription{
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #626875;
}

  .avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px; /* Ancho del avatar */
    height: 40px; /* Alto del avatar */
    border-radius: 50%; /* Para hacer el avatar redondo */
  }
  
  .avatar::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: 48px;
    height: 48px;
    border: 4px solid #fff;
    border-radius: 50%;
    z-index: -1;
    background-color: white;
  }



  
  