#modal-sub {
    --width: fit-content;
    --min-width: 328px;
    --height: 372px;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }
  
  .wrapper {
    width: 328px;
    height: 372px;
    background-color: white;
    padding: 21.62px 16px;
  }

  .modal-title{ 
    width:100%;
    margin-bottom:20px;
    text-align: center;
  }
  
  .modal-header{ 
    width:100%;
    display: flex;
    justify-content: end;
  }

  .modal-content{ 
    width: 296px;
    height: 100px;
    padding: 16px 8px 16px 8px;
    border: 1.5px solid #ED4D07;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal-footer{ 
    margin: 32px 0;
    text-align: center;
  }

  .button-subscribe{
    width: 296px;
    height: 40px;
  }

.ns-title{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #272727;
}

.ns-subtitle{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #D64200;
}

.ns-subtitle-2{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #4B4F58;
}

.ns-button{
  height: 40px;
  left: 21px;
  top: 288px;
  border-radius: 100px;
  padding: 10px 24px 10px 16px;
  border: 1px solid #898E9C;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #D64200;
  width: 100%;
}