.ion-img-session{ 
    object-fit: cover;
}

.ion-title-session{
    padding: 1rem;
    text-align: left;
    background-color: #F3F3F3;
}

.ion-title-header{
    font-size: 18px;
    font-family: 'Poppins';
    font-style: normal;
}


/* Estilos del boton de reservar sesion */

.ion-content-container{
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: #F3F3F3;
    padding: 16px;
}

.ion-content-reserve{ 
    display: flex;
    justify-content: space-between;
}

.ion-date-content{ 
    display:flex;
    flex-direction: column;
}

.ion-button-reserve{
    width: 131px;
    height: 40px;
    text-transform: none;
}

.max-participants{
    color: var(--neutral-600, #4B4F58);
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    margin-top: 8px;
}

/* Estilos de la descripcion de la sesion */

.ion-session-description-container{

    width: 100%;
    padding: 20px 20px 20px 20px;
}

.ion-description-content{
    display:flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.ion-description-title{
    font-size: 16px;
    font-weight: 700;
    line-height: 30px;
}

.ion-description-text{
    font-size: 14px;
    font-weight: 400;
    color: #626875;
}

.box-reserved-session-profile{
    display: flex;
    align-items: center;
    width: 118px;
    height: 50px;
    border: 1px solid #168404;
    border-radius: 8px;
}