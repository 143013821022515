ion-button {
    --border-radius: 20px;
}

ion-toolbar {
    --background: white;
    --color: black;
  }

ion-toast{
  --background: white;
}

.css-toast{
    color:#A81E16 !important;
}

.css-success-toast{
  color:#168404 !important;
}


.toast-button{
    color: #92949c !important;
    background-color: #92949c !important;
    fill: #92949c !important;
  }