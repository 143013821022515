.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }
  
  .controls {
    position: absolute;
    bottom: 250px;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
  }

  .button-controls {
    position: absolute;
    bottom: 100px;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }

  ion-range {
    --bar-background: #C2C4C8;
    --bar-background-active: #3d89cb;
    --bar-height: 4px;
    --bar-border-radius: 8px;
    --knob-background: #D64200;
    --knob-size: 16px;
    --pin-background: #D64200;
  }