.nc-container{
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.nc-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #272727;
}

.nc-button{
    height: 40px;
    left: 21px;
    top: 288px;
    border-radius: 100px;
    padding: 10px 24px 10px 16px;
    border: 1px solid #898E9C;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.004em;
    color: #D64200;
    margin-top: 24px;
}

.nc-img{
    border-radius: 4px;
    margin-top: 16px;
}