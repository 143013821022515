

.fundamentos-text{
    background: #FFFFFF;
    border-radius: 0px 0px 4px 4px ;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    width: 150px;
    text-align: center;
    padding: 14px 5px;
    max-height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fundamentos-img{
    width: 150px;
    height: 122px;
    border-radius: 4px 4px 0px 0px;
    background-size: cover;
    background-position: center;
}

.ce-border-fu{
    outline: 5px solid #ED4D07;
}


