#modal-video {
    --width: fit-content;
    --min-width: 100%;
    --height: 210px;
    --border-radius: 6px;
    --box-shadow: 0 18px 48px rgba(0, 0, 0, 0.2);
  }

  /* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
  .modalReactPlayer {
    height: calc(100%) !important;
    overflow-y: hidden !important;
  }
  #modal-video {
    --height: calc(100% - 120px) !important;
  }
}
  