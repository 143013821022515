.cdtext-container{
    width: 200px;
    height: 70px;
    padding: 1rem;
    border-radius: 50px;
    background: rgba(210, 207, 207, 0.7);
    /* opacity: 0.5; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: 10px;
    
}