#stripe-form-modal {
    --width: 80%;
    --min-width: 350px;
    --height: 440px;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }
  
  #stripe-form-modal h1 {
    margin: 20px 20px 10px 20px;
  }
  
  #stripe-form-modal ion-icon {
    margin-right: 6px;
  
    width: 48px;
    height: 48px;
  
    padding: 4px 0;
  
    color: #aaaaaa;
  }
  
  #stripe-form-modal .wrapper {
    margin-bottom: 10px;
  }

  .modal-content-stripe{
    padding: 1rem;
    background-color: #F3F3F3;
    height: 100%;
  }

  .header-stripe-modal{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .modal-header-close{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    height: 40px;
  }

  .ion-text-header{
    font-size: 16px;
    font-weight: 700;
    color: #272727;
  }