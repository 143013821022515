.es-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    gap: 4px;
    width: 100%;
    background: #FFF6F2;
    border: 1px solid #D64200;
    border-radius: 4px;
}

.es-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #D64200;
}

.es-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #4B4F58;
}