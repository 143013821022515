.stepper-container{ 
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 14px;
}

.step-icon-row{
    height: auto;
    padding-right: 10px;
    padding-top: 10px;
    z-index: 1000px;
}

.step-line{
    z-index: -100;
    height: 80px;
    width: 23px;
    border-right: 3px solid #C2C4C8;
}