.ioBackButton{
    --margin-start:'10px';
    --icon-padding-end:'10px';
    --icon-margin-end:'300px';
}

.a-container{
    padding: 20px 32px
}

.a-first-image-container{
    padding: 0;
    background-size: cover;
    position: relative;
    height: 90px;
    border-radius: 4px;
}

.a-defensa{
    background-image: url("../../assets/defensa_large.jpg");
    box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.14);
    background-position: center;
}

.a-tiro{
    background-image: url("../../assets/tiro_large.jpg");
    box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.14);
    background-position: center;
}

.a-pase{
    background-image: url("../../assets/pase_large.jpg");
    box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.14);
    background-position: center;
}

.a-tecnica-individual {
    background-image: url("../../assets/tecnica_individual_large.jpg");
    box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.14);
    background-position: center;
}

.a-tactica-individual{
    background-image: url("../../assets/tactica_individual_large.jpg");
    box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.14);
    background-position: center;
}

.a-image-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    position: absolute;
    top: 50%; 
    transform: translate(-50%, -50%); 
    background-clip: text; 
}

@media only screen and (min-width: 1224px) {
    .a-image-text{
        position: relative;
        left: 5% !important;
        top: 40%;
        font-size: 22px;
    }
    .a-first-image-container{
        height: 170px;
    }
  }

.a-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #272727;
}

.a-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4B4F58;
    margin-top: 2px;
}


