
  ion-modal#example-modal ion-icon {
    margin-right: 6px;
  
    width: 48px;
    height: 48px;
  
    padding: 10px 0;
  
    color: #aaaaaa;
  }
  
  ion-modal#example-modal .modalWrapper {
    padding: 20px;
  }

  .avatar-selected{ 
    width:84px;
    height:84px;
    border: 2.5px solid #ED4D07;
  }