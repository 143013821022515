
.lp-container{
    padding: 16px 32px;
}

.lp-first-child{
    display: flex;
    flex-direction: column;
    padding: 0;
}

.lp-title{
    font-size: 18px;
    font-weight: 600;
    font-family: Poppins;
    line-height: 24px;
    color: #272727;
}

.lp-subtitle{
    font-size: 14px;
    font-weight: 400;
    font-family: Lato;
    line-height: 20px;
    color: #4B4F58;
}

.lp-images-container{
    display: flex;
    padding: 0;
    margin-top: 16px;
}

.lp-images-container-1{
    display: flex;
    padding: 0;
    margin-top: 16px;
}


.lp-first-image-container{
    padding: 0;
    background-image: url("../../../assets/card_tecnica_individual.jpg");
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 50%;
    height: 90px;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
}

.lp-second-image-container{
    padding: 0;
    background-image: url("../../../assets/card_tactica_individual.jpg");
    background-size: cover;
    position: relative;
    width: 50%;
    height: 90px;
    border-radius: 4px;
    cursor: pointer;
}

.lp-image-text{
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 43%;
    transform: translate(-50%, -50%); 
    background-clip: text;
}

@media only screen and (min-width: 1224px) {
    .lp-image-text{
        color: #FFFFFF;
        font-size: 22px;
        line-height: 22px;
        font-weight: 700;
        position: relative;
        top: 40%;
        left: 40px;
    }
  }

.lp-third-image-container{
    padding: 0;
    background-image: url("../../../assets/card_defensa.jpg");
    background-size: cover;
    position: relative;
    width: 33%;
    height: 90px;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
}

.lp-fourth-image-container{
    padding: 0;
    background-image: url("../../../assets/card_tiro.png");
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 33%;
    height: 90px;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
}

.lp-fifth-image-container{
    padding: 0;
    background-image: url("../../../assets/card_pase.jpg");
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 33%;
    height: 90px;
    border-radius: 4px;
    cursor: pointer;
}

  /* Desktops and laptops ----------- */
  @media only screen and (min-width: 1224px) {
    .lp-first-image-container{
        height: 170px;
    }
    .lp-second-image-container{
        height: 170px;
    }
    .lp-third-image-container{
        height: 170px;
    }
    .lp-fourth-image-container{
        height: 170px;
    }
    .lp-fifth-image-container{
        height: 170px;
    }
  }