#modal-finish {
    --width: fit-content;
    --max-width: 500px;
    --min-width: 380px;
    --width: auto;
    --height: auto;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  }
  
  .wrapper-finished {
    padding: 8px;
    background-color: white;
  }