.module-name-header{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: white;
    margin-top: 5px;
}

.f-time-header{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: white;
    margin-left: 5.5px;

}

.ba-circle-text-header{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.004em;
    color: #FFFF;
}

.ba-circle-header {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 49px; /* Ancho del avatar */
    height: 49px; /* Alto del avatar */
    border-radius: 50%; /* Para hacer el avatar redondo */
  }
  
  .ba-circle-header::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    z-index: -1;
    background-color: #393A3F;
  }

  .ba-circle-text-module{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.004em;
    color: white;
    white-space: nowrap;
}

.progress-avatar-module {
    position: relative;
    width: 72px; /* Ancho del contenedor */
    height: 72px; /* Alto del contenedor */
    margin-right: 16px;
  }