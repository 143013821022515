ion-button[shape=circle] {
    --border-radius: 50%;
    width: 56px;
    height: 56px;
}


.react-player > video {
    position: absolute;
    object-fit: fill;
  }

  video {
    object-fit: cover;
  }
  
  .video-training-player2 {
    width: 100% !important;
  }


  .video-training-container-half {
    display: flex;
    flex-direction: column-reverse;
    padding: 5px 20%;
    .video-training-player2 {
      width: 100% !important;
    }
  }
  .hide {
    display: none;
  }

  .trainingVideoMode {
    width: 100% !important;
  }
  .black-bg {
    background-color: black !important;
  }