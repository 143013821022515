.ed-container{
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
}

.ed-text{
    color: var(--neutral-600, #4B4F58);
    font-size: 14px;
    font-family: Lato;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
}

.ed-green-box{
    background: var(--success-800, #168404);
    width: 100%;
    height: 64px;
    display: flex;
    padding: 12px 16px;
    align-items: center;
}

.ed-green-box-text{
    color: var(--neutral-white, #FFF);
    font-size: 14px;
    font-family: Lato;
    font-weight: 700;
    line-height: 20px;
}

.ed-red-box{
    background: var(--secondary-600, #B62D25);
    width: 100%;
    height: 64px;
    display: flex;
    padding: 12px 16px;
    align-items: center;
}

.ed-excercise-box{
    background: #F3F3F3;
    width: 100%;
    padding: 12px 16px;
}

.ed-module-name{
    color: var(--secondary-700, #A81E16);
    font-size: 12px;
    font-family: Lato;
    font-style: italic;
    line-height: 16px;
    letter-spacing: 0.4px;
}

.ed-exercise-name{
    color: var(--neutral-800, #272727);
    font-size: 20px;
    font-family: Lato;
    font-weight: 700;
    line-height: 24px;
    width: 89%;
}

.not-available{
    text-decoration: line-through;
    text-decoration-thickness: 1px;
    text-decoration-skip-ink: none;
}

.ed-no-available-exercises{
    color: var(--neutral-800, #272727);
    font-size: 14px;
    font-family: Lato;
    font-style: italic;
    line-height: 20px;
}

.ed-checkbox{
    color: var(--neutral-600, #4B4F58);
    font-size: 14px;
    font-family: Lato;
    line-height: 20px;
    margin-top: 18px;
}

.ed-modal-container{
    display: flex;
    flex-direction: column;
}

.ed-well-done{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #D64200;
}

.ed-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4B4F58;
}


.ed-button{
    background:  #D64200;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #FFFFFF;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-transform: none;
}