.ion-img {
    height: 35%;
    object-fit: cover;
}

.ion-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 65%;
}

.ion-grid-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 47px;
}

.ion-title {
    color: #272727;
    font-size: 22px;
    line-height: 32px;
    font-family: Poppins;
}

.ion-subtitle{
    color: #4B4F58;
    font-size: 16px; 
    line-height: 24px;
    text-align: center;
    width: 296px;
    margin-top: 16px;
}

.ion-button {
    background-color: #D64200;
    width: 296px;
    height: 40px;
    font-size: 14px; 
    line-height: 20px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 72px;
    border-radius: 100px;
    text-transform: capitalize;
}



