.qt-container-header{
    height: auto;
    max-height: 150px;
    min-height: 80px;
    background: #393A3F;
    border-radius: 0px 0px 40px 0px;
    padding: 16px 32px;
    display: flex; 
    flex-direction: column;
    width: 100%;
}

.qt-content-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.qt-content-first{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.qt-content-icons{
    display: flex;
    justify-content: space-between;
}

.qt-content-second{
    width: 80%;
}