.ce-header-container{
    display: flex;
    align-items: center;
    height: 64px;
    padding: 14px 16px;
}

.ce-header-title{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #272727;
    margin-left: 16px;
}

.ce-grid{
    display: flex;
    flex-direction: column;
    padding: 24px 32px;
    min-height: 100%;
    background: #272727;
    width: 100%;
    justify-content: space-between;
}

.ce-container{
    display: flex;
    flex-direction: column;
}

.ce-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
}

.ce-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}

.ce-minutes-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 22%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.ce-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.004em;
    color: #272727;
}

.ce-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 47%;
    height: 92px;
    background: #FFFFFF;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.ce-button-disabled{
    background: #626875;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #272727;
    margin-top: 24px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.ce-button{
    background:  #D64200;
    border-radius: 100px;
    padding: 10px 24px;
    height: 40px;
    color: #FFFFFF;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    text-transform: capitalize;
}

.ce-border{
    border: 5px solid #ED4D07;
}

.ce-no-available-exercises{
    color: var(--secondary-200, #FFB4AF);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 8px;
}