.module-finished-content{
    display:flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
}

.m-header-content{
    width: 296px;
    text-align: center;
}

.m-header-text{
    font-size: 22px;
    font-weight: 700;
}

.m-content-image{
    margin-top:10px;
    margin-bottom:10px;
}

.m-content-first{
    text-align: center;
    width: 296px;
    padding: 0.8rem;
}

.m-text-first{
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.m-text-second{
    font-size: 16px;
    font-weight: 700;
    text-align: center;
}

.m-text-third{
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}