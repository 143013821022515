/* HEADER */
.p-container{
    display: flex;
    padding: 0;
}

.p-title-container{
    min-height: 88px;
    background: #393A3F;
    border-radius: 0px 0px 40px 0px;
    padding: 16px;
    display: flex; 
    align-items: center;
}

.p-progress-avatar {
    position: relative;
    width: 104px; /* Ancho del contenedor */
    height: 104px; /* Alto del contenedor */
    margin-right: 16px;
}

.p-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    --background: #C2C4C8;
    --progress-background: #ED4D07; /* Color de fondo del progreso */
    --progress-height: 6px; /* Altura de la línea de progreso */
}

.p-ion-avatar{
    padding: 0;
    height: 76px;
    width: 76px;
}

.p-avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px; /* Ancho del avatar */
    height: 80px; /* Alto del avatar */
    border-radius: 50%; /* Para hacer el avatar redondo */
  }
  
  .p-avatar::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: 88px;
    height: 88px;
    border: 4px solid #393A3F;
    border-radius: 50%;
    z-index: -1;
    background-color: #393A3F;
  }
  .p-user-name{
    color:#FFF;
    font-size: 18px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 24px;
  }

  .p-user-points{
    color:#F3F3F3;
    font-size: 14px;
    font-family: Lato;
    line-height: 20px;
  }

  .p-user-points-description{
    color:#F3F3F3;
    font-size: 12px;
    font-family: Lato;
    font-style: italic;
    line-height: 16px;
    letter-spacing: 0.4px;
    margin-top: 11px;
  }

  /* STATISTICS */

  .p-statistics-container{
    padding: 24px 32px;
  }

  .p-tabs{
    width: 100%;
  }

.p-tabs-text{
  color: var(--neutral-600, #4B4F58);
  text-align: right;
  font-size: 14px;
  font-family: Lato;
  line-height: 20px;
}

.p-circle{
  border-radius: 26px;
  border: 0.8px solid var(--neutral-400, #898E9C);
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.p-statistics-points{
  color: var(--neutral-800, #272727);
  font-size: 14px;
  font-family: Lato;
  line-height: 24px;
}

.p-statistics-program{
  color: var(--neutral-800, #272727);
  font-size: 12px;
  font-family: Lato;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.p-statistics-info{
  flex-direction: column;
}

.p-doughnut-container{
  width: 116px;
  height: 116px;
}

/* PERFOMANCE GOALS */

.p-goals-container{
  display: flex;
  flex-direction: column;
  padding: 0 32px 24px 32px;
}

.p-goals-title{
  color: var(--neutral-800, #272727);
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.056px;
}

.p-goals-li{
  color: var(--neutral-800, #272727);
  font-size: 14px;
  font-family: Lato;
  line-height: 20px;
}

/* GOALS MODAL */

.p-icon-row{
  display: flex;
  justify-content: end;
  width: 100%;
}


.p-trash-icon{
  margin-right: 10px;
  color: #B62D25;
}


.p-trash-text{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #B62D25;
}

.p-notes-title{
  width: 100%;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272727;
}

.p-counter{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #393A3F;
  margin-top: 4px;
}

/* TABS SEGMENT */
.tabs-container {
  box-shadow: 0px 4px 12px 0px rgba(58, 65, 69, 0.12), 1px 1px 2px 0px rgba(58, 65, 69, 0.18);
}

.tabs-container .segment-button {
  flex: 0 0 auto; 
  width: 175px; 
  height: 48px;
  align-items: center;
}

.tab-label {
  display: inline-block;
  padding: 0 8px;
  color: var(--neutral-600, #4B4F58);
  text-align: center;
  font-size: 12px;
  font-family: Lato;
  line-height: 20px;
  text-transform: capitalize;
}

ion-segment-button{
  background: #F3F3F3;
}

ion-segment-button::part(indicator-background) {
  background: #F3F3F3;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid #D64200;
}

/* TAB APRENDIZAJE */
.p-aprendizaje-container{
  padding: 32px 32px 0 32px;
  display: flex;
  flex-direction: column;
}

.p-aprendizaje-title{
  color: var(--neutral-800, #272727);
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 24px;
}

.p-aprendizaje-child{
  display: flex;
  flex-direction: column;
}

.p-aprendizaje-text{
  color: var(--neutral-white, #FFF);
  font-size: 16px;
  font-family: Lato;
  font-weight: 700;
  line-height: 24px;
}

.p-aprendizaje-subtext{
  color: var(--neutral-100, #F3F3F3);
  font-size: 14px;
  font-family: Lato;
  line-height: 20px;
}

.p-box{
  border-radius: 4px;
  background: var(--neutral-700, #393A3F);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.20);
  padding: 12px 16px;
  width: 100%;
  height: 90px;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.p-aprendizaje-img{
  width: 42px;
  height: 44px;
  flex-shrink: 0;
}

.p-aprendizaje-progress-bar{
  height: 12px;
  border-radius: 5px;
  --progress-background: var(--primary-500, #ED4D07);
  --background: var(--neutral-400, #898E9C);
}

/* HISTORIAL */

.p-historial-container{
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.p-historial-title{
  color: var(--neutral-800, #272727);
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 24px;
}

.p-historial-div{
  border-radius: 4px;
  border: 1px solid var(--neutral-100, #F3F3F3);
  background: var(--neutral-700, #393A3F);
  width: 100%;
  padding: 4px 12px;
  margin-top: 20px;
}

.p-historial-day{
  color: var(--neutral-100, #F3F3F3);
  font-size: 16px;
  font-family: Lato;
  font-weight: 700;
  line-height: 24px;
}

.p-historial-exercise{
  color: var(--neutral-800, #272727);
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 24px;
}

.p-historial-description{
  color: var(--neutral-600, #4B4F58);
  font-size: 14px;
  font-family: Lato;
  line-height: 20px;
}