
.ion-grid-title-container-black-module{
    height: auto;
    background: #393A3F;
    border-radius: 0px 0px 40px 0px;
    padding: 16px 32px;
    display: flex; 
    flex-direction: column;
}

.ion-content-text-module{

    font-weight: 400;
    font-size: 12px;
}