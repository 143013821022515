.fe-container{
    display: flex;
    flex-direction: column;
}

.fe-well-done{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: #D64200;
}

.fe-subtitle{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #4B4F58;
}

.fe-points{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: #D64200;
    margin-top: 25px;
}

.fe-points-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #D64200;
}


.fe-button{
    height: 40px;
    border-radius: 100px;
    border: 1px solid #898E9C;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.004em;
    color: #D64200;
    margin-top: 24px;
}