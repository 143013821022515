.session-payinfo-content{
    width: 100%;
    min-width: 100%;
    height: 130px;
    background-color: #F3F3F3;
    display: flex;
    flex-direction: column;
    padding: 12px 16px 12px 16px ;
    border: 1px solid #C2C4C8;
    border-radius: 4px;
}

.session-text-info{ 
    font-size: 14px;
    font-weight: 400;
    color: #4B4F58;
    line-height: 20px;
}

.session-text-pay{ 
    font-size: 16px;
    font-weight: 600;
    color: #272727;
    margin-top: 8px;
}