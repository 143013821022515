.sb-container{
    height: 100%; 
    overflow: auto;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    margin-top: 40px;
}
.sb-ion-avatar{
    padding: 0;
    height: 36px;
    width: 36px;
}

.sb-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #272727;
}

.sb-description{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4B4F58;
}

.sb-level{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    color: #D64200;
    margin-top: 4px;
}

.sb-popover-content{
    width: 100%;
    display: flex;
    justify-content: space-between;
}


.sb-popover-content-child{
    display: flex;
}

.sb-divider{
    border: 1px solid #C2C4C8; 
}

.sb-content-container{
    padding: 16px 14px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
}


.sb-progress-avatar {
    position: relative;
    width: 64px; /* Ancho del contenedor */
    height: 64px; /* Alto del contenedor */
    margin-right: 16px;
}

.sb-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    --background: #C2C4C8;
    --progress-background: #ED4D07; /* Color de fondo del progreso */
    --progress-height: 6px; /* Altura de la línea de progreso */
}

.sb-avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px; /* Ancho del avatar */
    height: 40px; /* Alto del avatar */
    border-radius: 50%; /* Para hacer el avatar redondo */
}

.sb-avatar::before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    width: 48px;
    height: 48px;
    border: 4px solid #fff;
    border-radius: 50%;
    z-index: -1;
    background-color: white;
    }

.sb-close-icon{
    width: 14px;
    height: 14px;
}

.sb-ion-img-wrapper{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    @media (min-width: 1200px) {
        display: none;
    }
}

.sb-ion-list{
    display: flex;
    flex-direction: column;
}

.sb-ion-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    padding: 0 16px;
    display: flex;
    width: 100%;
}

.sb-ion-subtext{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    padding: 0 32px;
}

.sb-ion-subtext::before{
    content: '· ';
    margin-right: 4px;
    font-weight: bolder;
    font-size: 18px;
}

.sb-accordion-first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
}

.sb-accordion-second-child{
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}

.sb-accordion-second-child.show {
    max-height: 200px; 
  }

.sb-mt-24{
    margin-top: 24px;
}

  




  
  
  


  
  
  