

.f-first-image-container{
    padding: 0;
    background-size: cover;
    position: relative;
    height: 170px;
    margin-top: 12px;
}

.defensa{
    background-image: url("../../assets/defensa_large.jpg");
    background-position: center;
}

.tiro{
    background-image: url("../../assets/tiro_large.jpg");
    background-position: center;
}

.pase{
    background-image: url("../../assets/pase_large.jpg");
    background-position: center;
}

.tecnica-individual {
    background-image: url("../../assets/tecnica_individual_large.jpg");
    background-position: center;
}

.tactica-individual{
    background-image: url("../../assets/tactica_individual_large.jpg");
    background-position: center;
}

.f-image-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    position: absolute;
    top: 50%; 
    transform: translate(-50%, -50%); 
    background-clip: text; 
}

.f-image-icon{
    position: absolute;
    top: 50%; 
    left: 12%; 
    transform: translate(-50%, -50%); 
    background-clip: text; 
}

.f-arrow{
    position: absolute;
    top: 48%; 
    left: 87%; 
    transform: translate(-50%, -50%); 
    background-clip: text; 
}

.f-accordion-second-child{
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
}

.f-accordion-second-child.show {
    max-height: fit-content; 
  }


.f-child {
    background: #F3F3F3;
    border: 1px solid #C2C4C8;
    padding: 12px 16px;
}

.f-excercise-container{
    display: flex; 
    width: 100%; 
    justify-content: space-between;
    margin-top: 8px;
}

.f-excercise-name{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #272727;
   
}

.f-time{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #4B4F58;
    margin-left: 5.5px;

}

.f-module-name{
    font-family: 'Lato';
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #A81E16;
}

.f-play-button{
    border-radius: 100px;
    border: 1px solid #898E9C;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.004em;
    color: #D64200;
}

.f-button-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.004em;
    color: #D64200;
}

.flex{
    display: flex;
}

.f-icon-container{
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.f-circle{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5em;
    height: 2.5em;
    border: 1px solid  #D64200;
    border-radius: 50%;
}

.f-icon{
    height: 13.5px;
    width: 14.62px; 
    fill: #D64200;
}

.f-icon-row{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }


.f-trash-icon{
    margin-right: 10px;
    color: #B62D25;
}

.f-trash-text{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #B62D25;
}

.f-notes-title{
    width: 100%;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #272727;
    margin-top: 26px;
}

.f-counter{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: #393A3F;
    margin-top: 4px;
}
