.cp-modal {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 12px;
  }
  
  .wrapper{
    background: #F3F3F3;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    width: 328px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 21.62px 16px;
  }
  
  .cp-icon-row{
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  
  .cp-icon{
    color: #1E1E1E;
    font-weight: bold;
  }

.cp-terms{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.4px;
    text-decoration-line: underline;
    color: #B83A02;
}

.cp-subscribe-plan{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #272727;
  text-align: center;
}

.cp-subscribe-text{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #272727;
  margin-top: 10px;
}

.cp-terms-text{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #898E9C;
  margin-top: 25px;
}

.cp-card-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 19.5px 16px;
  gap: 4px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  border: 1px solid #ED4D07;
}

.cp-price{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #272727;
}

.cp-plan{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #272727;
}

.cp-plan-text{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: #626875;
}

.cp-button{
  height: 40px;
  border-radius: 100px;
  padding: 10px 24px 10px 16px;
  border: 1px solid #898E9C;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: #D64200;
  margin-top: 45px;
  width: 100%;
}


  

  
 
  
